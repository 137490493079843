<template>
	<form
		class="form-create-rate main-wrapper mt-4"
		@submit.prevent="handleSubmit"
	>
		<CRow class="mb-5">
			<CCol md="12">
				<h4>Additional fee</h4>
			</CCol>
			<CCol md="4">
				<div class="form-group mt-3">
					<BaseInputNumber
						id="shipping-fee"
						v-model="$v.localFee.$model"
						:decimal-scale="2"
						:is-valid="!$v.localFee.$error"
						:invalid-feedback="feeErrors"
						label="Additional fee"
						prepend-text="฿"
						text-align="right"
						placeholder="0.00"
					/>
				</div>
			</CCol>
			<CCol md="12">
				<hr class="mb-5">
			</CCol>
			<CCol md="12" class="d-flex justify-content-between mb-3">
				<h4>Categories</h4>
				<CButton color="secondary" @click="handleAddCategory">
					{{ !localCategories.length ? 'Add' : 'Edit' }}
				</CButton>
			</CCol>
			<CCol md="12">
				<PreviewBoxCategory
					v-if="localCategories.length"
					:categories="localCategories"
				/>
				<BaseNoItemBanner
					v-else
					:is-valid="!$v.localCategories.$error"
					:invalid-feedback="$t('global.error.required')"
					text="There are no category for this additional fee"
				/>
			</CCol>
			<CCol md="12">
				<hr class="mt-5 mb-5">
			</CCol>
		</CRow>

		<BaseActionPanelStickyFooter
			:disabled-confirm="isSubmitting"
			:is-edit="isEdit"
			:remove-text="isEdit ? 'Remove rule' : null"
			content-class="main-wrapper"
			@onConfirm="handleSubmit"
			@onCancel="$router.push({ name: 'ShippingSetting'})"
			@onRemove="isEdit ? $refs['modal-remove'].open() : null"
		/>
		<ModalCategory
			ref="modal-category"
			title="Select categories"
			:selected-ids="categoryIds"
			@onSubmit="handleSubmitCategory"
			@onCancel="handleCancelCategoryModal"
		/>
		<BaseModalConfirmDelete
			ref="modal-remove"
			title="Delete this Categories additional fee rule?"
			description="Deleting rule will affect upcoming orders immediately."
			@onSuccess="handleRemoveRule"
		/>
	</form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { required, maxValue } from 'vuelidate/lib/validators';
import ModalCategory from '@/components/ModalCategory.vue';
import PreviewBoxCategory from '@/components/PreviewBoxCategory.vue';
import { MAXIMUM_FEE } from '../enums/shippings';

export default {
	name: 'FormCategoryAdditionalFee',

	validations: {
		localFee: {
			required,
			maxValue: maxValue(MAXIMUM_FEE),
		},
		localCategories: {
			required,
		},
	},
	components: {
		ModalCategory,
		PreviewBoxCategory,
	},
	props: {
		fee: {
			type: [String, Number],
			default: '',
		},
		categoryList: {
			type: Array,
			default: () => [],
		},
		isSubmitting: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			localFee: null,
			localCategories: [],
		};
	},
	computed: {
		...mapGetters({
			getSelectedCategories: 'categorySelector/getSelectedCategories',
		}),

		feeErrors() {
			if (!this.$v.localFee.required) {
				return this.$t('global.error.required');
			}

			if (!this.$v.localFee.maxValue) {
				return 'Please input the maximum number 99,999,999.99';
			}

			return null;
		},

		categoryIds() {
			return this.localCategories.map((category) => category.id);
		},

		isEdit() {
			return this.$listeners.onRemoveRule != null;
		},
	},
	mounted() {
		this.localFee = this.fee;
		this.preSelectCategories(this.categoryList.map((category) => category.id));
		this.localCategories = this.getSelectedCategories;
	},
	methods: {
		...mapActions({
			preSelectCategories: 'categorySelector/preSelectCategories',
		}),
		handleSubmitCategory(categories = []) {
			this.localCategories = categories;
		},
		handleCancelCategoryModal() {
			// Reset category data when user close/cancel category modal
			this.preSelectCategories(this.categoryIds);
		},
		handleAddCategory() {
			this.$refs['modal-category'].open();
		},
		handleRemoveRule() {
			this.$emit('onRemoveRule');
		},
		async handleSubmit() {
			this.$v.$touch();

			if (this.$v.$invalid) {
				return;
			}
			this.$emit('onSubmit', this.localFee, this.categoryIds);
		},
	},
};
</script>
